<template>
  <div class="apartment-search-filter d-flex flex-column flex-md-row p-8">
    <v-date-picker
        v-model="reservationRange"
        :min-date="today"
        class="d-flex align-items-center"
        is-range>
      <template v-slot="{ inputValue, inputEvents, isDragging }">
        <div class="date-picker left position-relative h-100 w-100">
          <span class="position-absolute info-date text-uppercase fw-semi text-xs">Llegada</span>
          <input :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                 :placeholder="datePlaceholder"
                 :value="inputValue.start" class="flex-grow pt-24 px-32 pb-12 bg-gray-100 w-100 h-100"
                 v-on="inputEvents.start"
          />
        </div>

        <div class="date-picker right position-relative h-100 w-100">
          <span class="position-absolute info-date text-uppercase fw-semi text-xs">Salida</span>
          <input :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                 :placeholder="datePlaceholder"
                 :value="inputValue.end" class="flex-grow pt-24 px-32 pb-12 bg-gray-100 w-100 h-100"
                 v-on="inputEvents.end"
          />
        </div>
      </template>
    </v-date-picker>

    <div class="guest d-flex align-items-center px-sm-12 py-sm-12 p-lg-0">
      <div class="dropdown w-100">
        <span class="position-absolute info-date text-uppercase fw-semi text-xs">Huéspedes</span>

        <button id="guests" aria-expanded="false"
                class="btn text-start dropdown-toggle d-flex align-items-center justify-content-between pt-24 px-32 pb-12 w-100"
                data-bs-toggle="dropdown" role="button" type="button"
        >
          {{ totalGuests }} huéspedes
        </button>

        <ul id="counter-dropdown" aria-labelledby="guests"
            class="dropdown-menu px-16">
          <li>
            <div class="dropdown-item d-flex align-items-center justify-content-between">
              <div class="data pe-16">
                <div class="fw-bold">Adultos</div>
                <div class="">A partir de 13 años</div>
              </div>

              <div class="counter d-flex align-items-center">
                <button :disabled="guests.adults <= 1" class="less" type="button" @click="reduceGuests('adult')">
                  <span class="icon icon-minus"></span>
                </button>

                <div class="number px-16">{{ guests.adults }}</div>
                <button class="plus" type="button" @click="addGuests('adult')">
                  <span class="icon icon-plus"></span>
                </button>
              </div>
            </div>
          </li>

          <li>
            <div class="dropdown-item d-flex align-items-end justify-content-between">
              <div class="data">
                <div class="fw-bold">Niños</div>
                <div class="">De 2 a 12 años</div>
              </div>

              <div class="counter d-flex align-items-center">
                <button class="less" type="button" @click="reduceGuests('children')">
                  <span class="icon icon-minus"></span>
                </button>

                <div class="number px-16">
                  {{ guests.children }}
                </div>

                <button class="plus" type="button" @click="addGuests('children')">
                  <span class="icon icon-plus"></span>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="search px-12 pb-4 pt-8 py-sm-12 p-lg-0 me-4">
        <div class="btn-section d-flex align-items-center justify-content-center h-100">
          <button :disabled="reservationRange.start === '' && reservationRange.end === ''" class="btn btn-primary btn-search p-16"
                  type="button"
                  @click="goToSearchResultsURI">
            <span class="icon icon-search text-md"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  name: 'VSearchFilter',
  data() {
    return {
      reservationRange: {
        start: '',
        end: '',
      },
      datePlaceholder: 'Añade una fecha',
      guests: {
        adults: 1,
        children: 0,
      },
    };
  },
  computed: {
    today() {
      return DateTime.local();
    },
    totalGuests() {
      return parseInt(this.guests.adults) + parseInt(this.guests.children);
    },
  },
  created() {
    this.setSearchFilters();
  },
  mounted() {
    document.getElementById('counter-dropdown').addEventListener('click', function (e) {
      e.stopPropagation();
    });
  },
  methods: {
    setSearchFilters() {
      let urlParams = new URLSearchParams(window.location.search);

      if (urlParams.has('startDate')) {
        this.reservationRange.start = DateTime.fromISO(urlParams.get('startDate')).toJSDate();
      }

      if (urlParams.has('endDate')) {
        this.reservationRange.end = DateTime.fromISO(urlParams.get('endDate')).toJSDate();
      }

      if (urlParams.has('adults')) {
        this.guests.adults = urlParams.get('adults');
      }

      if (urlParams.has('children')) {
        this.guests.children = urlParams.get('children');
      }
    },
    addGuests(guestType) {
      if (guestType === 'adult') {
        this.guests.adults++;
      } else if (guestType === 'children') {
        this.guests.children++;
      }
    },
    reduceGuests(guestType) {
      if (this.guests.children >= 0) {
        if (guestType === 'adult') {
          this.guests.adults--;
        } else if (guestType === 'children') {
          this.guests.children--;
        }
      }
    },
    goToSearchResultsURI() {
      window.location.href = '/apartamentos' +
          '?startDate=' + DateTime.fromJSDate(this.reservationRange.start).toISODate() +
          '&endDate=' + DateTime.fromJSDate(this.reservationRange.end).toISODate() +
          '&adults=' + this.guests.adults +
          '&children=' + this.guests.children;
    },
  }
};
</script>

<style lang="scss">
.apartment-search-filter {
  position: relative;
  z-index: 9999;

  .date-picker {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      background: #A0A3BDFF;

      @media (min-width: 768px) {
        top: 50%;
        right: 0;
        width: 1px;
        height: calc(100% - 24px);
        transform: translateY(-50%);
      }

      @media (max-width: 767px) {
        bottom: 0;
        left: 50%;
        width: calc(100% - 24px);
        height: 1px;
        transform: translateX(-50%);
      }
    }

    &.left {
      input {
        border-top-left-radius: 40px;

        @media (min-width: 575px) {
          border-bottom-left-radius: 40px;
        }
      }
    }

    &.right {
      input {
        @media (max-width: 574px) {
          border-top-right-radius: 40px;
        }
      }
    }
  }

  .info-date {
    top: 6px;
    left: 32px;
  }

  input {
    border: none;
  }

  .counter .icon {
    font-size: .625rem
  }

  .btn-search {
    border-radius: 50%;
  }

  .guest {
    .dropdown-toggle {
      border: 0;
    }
  }
}
</style>

import axios from "axios";

const api = axios.create({
    baseURL: '/wp-json/v1',
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});

api.interceptors.response.use((response) => response, (error) => {
    if (error.request) {
        if (!axios.isCancel(error)) {
            throw error;
        }
    }
});

export default api;
<template>
  <div id="building-select">
    <select v-model="building" aria-label="locations" class="form-select building-select"
            @change="goToSearchResultsURI()">
      <option :selected="building === 0" selected value="0">Todas las localizaciones</option>
      <option v-for="databuilding of data"
              :key="databuilding.id"
              :selected="databuilding.id === building" :value="databuilding.id">{{ databuilding.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'VBuildingFilter',
  props: {
    data: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      building: 0,
      reservationRange: {
        start: '',
        end: '',
      },
      guests: {
        adults: 0,
        children: 0,
      },
    };
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('startDate')) {
      this.reservationRange.start = urlParams.get('startDate');
    }

    if (urlParams.has('endDate')) {
      this.reservationRange.end = urlParams.get('endDate');
    }

    if (urlParams.has('adults')) {
      this.guests.adults = urlParams.get('adults');
    }

    if (urlParams.has('children')) {
      this.guests.children = urlParams.get('children');
    }

    if (urlParams.has('building')) {
      this.building = urlParams.get('building');
    }
  },
  methods: {
    goToSearchResultsURI() {
      let url = new URL(window.location.href);

      url = '/apartamentos';

      if (!!this.reservationRange.start && !!this.reservationRange.end
          && !!this.guests.adults && !!this.guests.children) {

        url +=
            '?startDate=' + this.reservationRange.start +
            '&endDate=' + this.reservationRange.end +
            '&adults=' + this.guests.adults +
            '&children=' + this.guests.children;

        if (this.building !== '0') {
          url +=
              '&building=' + this.building;
        }
      } else {
        if (this.building !== '0') {
          url +=
              '?building=' + this.building;
        }
      }
      window.location.href = url;
    },
  }
};
</script>
<style lang="scss" scoped>
.form-select {
  background-image: none !important;
  border: 1px var(--uq-primary) solid !important;
  border-radius: 30px!important;
}

#building-select {
  display: flex;
  position: relative;

  &:after {
    content: "";
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

</style>
<template>
  <div ref="map" class="map w-100 h-100"></div>
</template>

<script>
import {Loader} from '@googlemaps/js-api-loader';

export default {
  name: 'VMap',
  props: {
    locations: {
      type: Array,
      required: true,
    },
    zoom: {
      type: Number,
      required: true,
      default: 15,
    },
  },
  async mounted() {
    await this.loadGoogleMap();
  },
  methods: {
    async loadGoogleMap() {
      const locations = this.locations;

      const loader = new Loader({
        apiKey: `${process.env.MIX_GOOGLE_MAPS_API_KEY}` ?? '',
        version: "weekly",
        libraries: ["places"]
      });

      const mapOptions = {
        zoom: this.zoom,
        center: {
          lat: parseFloat(locations[0].lat),
          lng: parseFloat(locations[0].lng),
        },
      };

      const google = await loader.load();

      const map = new google.maps.Map(this.$refs.map, mapOptions);

      // TODO FAILING HERE
      const bounds = new google.maps.LatLngBounds();
      const infoWindow = new google.maps.InfoWindow();

      for (let i = 0; i < locations.length; i++) {
        let marker = new google.maps.Marker({
          position: {
            lat: parseFloat(locations[i].lat),
            lng: parseFloat(locations[i].lng),
          },
          map,
        });

        bounds.extend(marker.position);

        google.maps.event.addListener(marker, 'click', (function(marker, i) {
          return function() {
            let link = '';

            if (locations[i].link) {
              link = '<a href="' + locations[i].link +'" target="_blank" class="location-link d-block text-sm fw-medium px-12 py-4">' + locations[i].title + '</a>';
            } else {
              link = '<span class="d-block text-sm fw-medium px-12 py-4">' + locations[i].title + '</span>';
            }

            infoWindow.setContent(link);
            infoWindow.open(map, marker);
          }
        })(marker, i));
      }

      const elZoom = this.zoom

      google.maps.event.addListener(map, 'zoom_changed', function() {
        const zoomChangeBoundsListener =
            google.maps.event.addListener(map, 'bounds_changed', function(event) {
              if (this.getZoom() > 15 && this.initialZoom === true) {
                this.initialZoom = false;
                this.setZoom(elZoom);
              }
              google.maps.event.removeListener(zoomChangeBoundsListener);
            });
      });

      map.initialZoom = true;
      map.fitBounds(bounds);
    },
  }
}
</script>

<style scoped>

</style>

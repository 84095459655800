<template>
  <component :is="tag" class="tooltip-box" ref="tooltip">
    <slot/>
  </component>
</template>

<script>
import Tooltip from 'bootstrap/js/src/tooltip';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VTooltip',
  data() {
    return {
      tooltip: '',
    };
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    title: {
      type: String,
      required: true,
      default: 'Tooltip',
    },
    top: Boolean,
    right: Boolean,
    left: Boolean,
    bottom: {
      type: Boolean,
      default: true,
    },
    delay: {
      type: Number,
      default: 0,
    },
    trigger: {
      type: String,
      default: 'hover', // click, hover, focus, manual
    },
  },
  computed: {
    placement() {
      let placement = '';

      if (this.top || this.right || this.left) {
        placement = this.top ? 'top' : placement;
        placement = this.right ? 'right' : placement;
        placement = this.left ? 'left' : placement;

        return placement;
      }

      return 'bottom';
    },
  },
  methods: {
    createInstance() {
      const element = this.$refs.tooltip;

      this.tooltip = new Tooltip(element, {
        container: 'body',
        title: this.title,
        delay: this.delay,
        placement: this.placement,
        trigger: this.trigger,
      });
    },
    show() {
      this.tooltip.show();
    },
    dispose() {
      this.tooltip.dispose();
    },
    enable() {
      this.tooltip.enable();
    },
    disable() {
      this.tooltip.disable();
    },
    toggleEnable() {
      this.tooltip.toggleEnable();
    },
    updatePlacement(placement) {
      this.tooltip.update(placement);
    },
  },
  mounted() {
    this.createInstance();
  },
});
</script>

<style lang="scss">
.tooltip-box {
  position: relative !important;
  opacity: 1 !important;
  height: auto;
  width: fit-content;
}

.tooltip {
  padding: 5px;
  filter: drop-shadow(.25rem 0 .5rem var(--uq-gray-400));
}
</style>
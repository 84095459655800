<template>
  <span v-if="loading" class="loader">Cargando...</span>

  <template v-else-if="!!this.price">
    <span class="text-xxl fw-bold">{{ price }} € </span>
    <span class="ms-8 decoration">por noche </span>
    <span class="text-md text-black-50 ms-8">{{ totalFinalPrice }} € en total</span>
  </template>
</template>

<script>
import { DateTime } from 'luxon';
import api from '../api';

export default {
  name: 'VPrice',
  props: ['roomTypeId'],
  data() {
    return {
      price: null,
      totalFinalPrice: 0,
      loading: true,
      startDate: null,
      endDate: null,
      adults: null,
      children: null,
    };
  },
  async mounted() {
    let query = new URLSearchParams(window.location.search);

    if (query.has('startDate') && query.has('endDate') && query.has('adults') && query.has('children')) {
      this.startDate = DateTime.fromISO(query.get('startDate')).toISODate();
      this.endDate = DateTime.fromISO(query.get('endDate')).toISODate();
      this.adults = parseInt(query.get('adults'));
      this.children = parseInt(query.get('children'));

      await this.getPrice();
    } else {
      this.loading = false;
    }
  },
  methods: {
    async getPrice() {
      try {
        const { data } = await api.get('/cloudbeds/rate' +
            '?roomTypeID=' + this.roomTypeId +
            '&startDate=' + this.startDate +
            '&endDate=' + this.endDate +
            '&adults=' + this.adults +
            '&children=' + this.children +
            '&detailedRates=0'
        );

        if (data.totalRate) {
          this.price = data.averageRate;
          this.totalFinalPrice = data.totalRate;
        } else {
          this.reservationRange.start = '';
          this.reservationRange.end = '';
        }

      } catch (e) {
        // Silence is golden
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    reservationRange() {
      this.getPeriodRate();
    },
  },
};
</script>

<style lang="scss" scoped>
  .decoration {
    position: relative;

    &:after {
      position: absolute;
      content: "";
      width: 2px;
      height: 2px;
      border-radius: 20px;
      background-color: black;
      bottom: 45%;
      right: -8%;
     }
  }
</style>

import {createApp} from "vue";
import { SetupCalendar, Calendar as VCalendar, DatePicker as VDatePicker } from 'v-calendar';
import VBooking from "./VBooking";
import VSearchFilter from "./VSearchFilter";
import VBuildingFilter from "./VBuildingFilter";
import VMap from "./VMap";
import VPrice from "./VPrice";
import VToolTip from "./VToolTip.vue";

const rootApp = {
    components: {
        VBooking,
        VSearchFilter,
        VBuildingFilter,
        VMap,
        VPrice,
        VToolTip,
    },
}

const appFactory = () => {
    const app = createApp({...rootApp});

    app.use(SetupCalendar, {})
    app.component('v-calendar', VCalendar);
    app.component('v-date-picker', VDatePicker);

    return app;
};

const vueElements = document.getElementsByClassName('v-component');

for (let vueElement of vueElements) {
    appFactory().mount(document.getElementById(vueElement.id));
}

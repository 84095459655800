<template>
  <div class="booking-section p-24">
    <div class="mb-24">
      <template v-if="reservationRange.start && reservationRange.end && totalGuests <= guestLimit && !warning">
        <span v-if="loading" class="loader">Loading...</span>

        <template v-else>
          <div class="d-flex justify-content-space-between align-items-center">
            <template v-if="offeredSingleNightPrice">
              <span class="text-xxl fw-bold">{{ offeredSingleNightPrice }}€ </span>
              <span>/ noche</span>
            </template>

            <div :class="{'text-decoration-line-through ms-8': offeredSingleNightPrice}">
              <span :class="{'text-md': offeredSingleNightPrice, 'text-xxl': !offeredSingleNightPrice}"
                    class="fw-bold">{{ singleNightPrice }}€ </span>
              <span :class="{'text-md': offeredSingleNightPrice}">/ noche</span>
            </div>
          </div>
        </template>
      </template>

      <template v-else-if="totalGuests > guestLimit">
        <span v-if="loading" class="loader">Loading...</span>

        <template v-else>
          <span class="text-xl lh-sm fw-semi">Este apartamento no está disponible para ese número de huéspedes</span>
        </template>
      </template>

      <template v-else-if="warning">
        <span class="text-lg lh-sm fw-semi">{{ warning }}</span>
      </template>

      <span v-else class="text-xl lh-sm fw-semi">Añade las fechas para ver los precios</span>
    </div>

    <div class="row g-0 book-info mb-16">
      <v-date-picker
          v-model="reservationRange"
          :disabled-dates="blockedDates"
          :min-date="today"
          is-range
      >
        <template v-slot="{ inputValue, inputEvents, isDragging }">
          <div class="row g-0">
            <div class="col-6 left d-flex align-items-center justify-content-center">
              <div class="position-relative h-100">
                <span class="position-absolute info-date text-uppercase fw-semi text-xs">Llegada</span>
                <input :class="datePlaceholder"
                       :placeholder="datePlaceholder"
                       :value="convertJSDate(reservationRange.start)"
                       class="flex-grow pt-24 px-12 pb-12 bg-gray-100 w-100 h-100"
                       v-on="inputEvents.start"
                />
              </div>
            </div>

            <div class="col-6 right d-flex align-items-center justify-content-center">
              <div class="position-relative h-100">
                <span class="position-absolute info-date text-uppercase fw-semi text-xs">Salida</span>
                <input :class="datePlaceholder"
                       :placeholder="datePlaceholder"
                       :value="convertJSDate(reservationRange.end)"
                       class="flex-grow pt-24 px-12 pb-12 bg-gray-100 w-100 h-100"
                       v-on="inputEvents.end"
                />
              </div>
            </div>
          </div>
        </template>
      </v-date-picker>

      <div class="col-12 bottom">
        <div class="position-relative dropdown">
          <span class="position-absolute info-date text-uppercase fw-semi text-xs">Huéspedes</span>

          <button id="guests" aria-expanded="false"
                  class="btn text-start dropdown-toggle d-flex align-items-center justify-content-between pt-24 px-12 pb-12 w-100"
                  data-bs-toggle="dropdown" role="button" type="button"
          >
            {{ totalGuests }} huéspedes
          </button>

          <ul id="counter-dropdown" aria-labelledby="guests"
              class="dropdown-menu w-100">
            <li>
              <div class="dropdown-item d-flex align-items-end justify-content-between">
                <div class="data">
                  <div class="fw-bold">Adultos</div>
                  <div class="">A partir de 13 años</div>
                </div>

                <div class="counter d-flex align-items-center">
                  <button :disabled="guests.adults <= 1" class="less" type="button" @click="reduceGuests('adult')">
                    <span class="icon icon-minus"></span>
                  </button>

                  <div class="number px-16">{{ guests.adults }}</div>

                  <button :disabled="totalGuests >= guestLimit" class="plus" type="button" @click="addGuests('adult')">
                    <span class="icon icon-plus"></span>
                  </button>
                </div>
              </div>
            </li>

            <li>
              <div class="dropdown-item d-flex align-items-end justify-content-between">
                <div class="data">
                  <div class="fw-bold">Niños</div>
                  <div class="">De 2 a 12 años</div>
                </div>

                <div class="counter d-flex align-items-center">
                  <button :disabled="guests.children <= 0" class="less" type="button" @click="reduceGuests('children')">
                    <span class="icon icon-minus"></span>
                  </button>

                  <div class="number px-16">
                    {{ guests.children }}
                  </div>

                  <button :disabled="totalGuests >= guestLimit" class="plus" type="button"
                          @click="addGuests('children')">
                    <span class="icon icon-plus"></span>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <template v-if="reservationRange.start && reservationRange.end && !loading && !warning">
      <div class="btn-section mb-24">
        <a class="btn btn-primary mb-16" @click="goToReservationURI">
          Reservar
        </a>

        <div class="text-center">
          No se te cobrará nada aún
        </div>
      </div>

      <div class="resume">
        <div class="charges divider mb-16 pb-16">
          <div class="price-night d-flex align-items-center justify-content-between">
            <span class="text-decoration-underline">
              {{ `${singleNightPrice}€ x ${totalNights} noches` }}
            </span>

            <span :class="{'text-decoration-line-through': offeredTotalNightsPrice}">{{ totalNightsPrice + '€' }}</span>
          </div>

          <div v-if="offeredTotalNightsPrice"
               class="price-night d-flex align-items-center justify-content-between">

            <div class="d-flex align-items-center gap-16 m-0">
              <span>
                Descuento de {{ `${discount}${discountType}` }}
              </span>

              <v-tool-tip v-if="offeredSingleNightPrice" :title="discountInfo">
                <template #default>
                  <span class="icon icon-info"></span>
                </template>
              </v-tool-tip>
            </div>

            <span> {{ `${offeredTotalNightsPrice}€` }}</span>


          </div>

          <div class="service d-flex align-items-center justify-content-between">

            <div class="d-flex align-items-center gap-16 m-0">
              <span>Cargos Adicionales</span>

              <v-tool-tip :title="textInfo">
                <template #default>
                  <span class="icon icon-info"></span>
                </template>
              </v-tool-tip>
            </div>

            <span>{{ totalExtraCharges + '€' }}</span>
          </div>
        </div>

        <div class="total d-flex align-items-center justify-content-between">
          <span class="fw-bold">Total</span>

          <span :class="{'text-decoration-line-through': offeredTotalFinalPrice}"
                class="fw-bold"> {{ totalFinalPrice + '€' }}</span>
        </div>

        <div v-if="offeredTotalFinalPrice"
             class="total d-flex align-items-center justify-content-between">
          <span class="fw-bold"> {{ offeredTotalFinalPrice + '€' }}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import api from '../api';
import VToolTip from './VToolTip.vue';

export default {
  name: 'VBooking',
  components: {
    VToolTip
  },
  props: {
    roomId: {
      required: true,
      type: Number
    },
    roomTypeId: {
      required: true,
      type: Number
    },
    guestLimit: {
      required: true,
      type: Number
    },
    blockedDates: {
      required: true,
      type: Array
    },
    reservationUrl: {
      required: true,
      type: String,
    },
    textInfo: {
      required: true,
      type: String,
    },
    discountInfo: {
      required: true,
      type: String,
    },
  },
  computed: {
    today() {
      return DateTime.local();
    },
    totalGuests() {
      return this.guests.adults + this.guests.children;
    },
    totalNights() {
      return Math.round((DateTime.fromJSDate(this.reservationRange.end).toMillis() - DateTime.fromJSDate(this.reservationRange.start).toMillis()) / (1000 * 60 * 60 * 24));
    },
  },
  data() {
    return {
      reservationRange: {
        start: '',
        end: '',
      },
      datePlaceholder: 'Añade una fecha',
      guests: {
        adults: 1,
        children: 0
      },
      singleNightPrice: 0,
      singleNightPriceNumber: 0,
      totalNightsPrice: 0,
      totalExtraCharges: 0,
      totalFinalPrice: 0,
      offeredSingleNightPrice: 0,
      offeredSingleNightPriceNumber: 0,
      offeredTotalNightsPrice: 0,
      offeredTotalExtraCharges: 0,
      offeredTotalFinalPrice: 0,

      discount: 0,
      discountType: '',

      warning: '',

      loading: false,
    };
  },
  async mounted() {

    let query = new URLSearchParams(window.location.search);

    if (query.has('startDate') && query.has('endDate') && query.has('adults') && query.has('children')) {
      this.reservationRange.start = DateTime.fromISO(query.get('startDate')).toJSDate();
      this.reservationRange.end = DateTime.fromISO(query.get('endDate')).toJSDate();
      this.guests.adults = parseInt(query.get('adults'));
      this.guests.children = parseInt(query.get('children'));

      await this.getPeriodRate();
    }

    document.getElementById('counter-dropdown').addEventListener('click', function (e) {
      e.stopPropagation();
    });

  },
  methods: {
    convertJSDate(date) {
      return date ? DateTime.fromJSDate(date).toISODate() : null;
    },
    toggleDropdownGuests() {
      this.openDropdownGuests = !this.openDropdownGuests;
    },
    addGuests(guestType) {
      if (this.totalGuests < this.guestLimit) {
        if (guestType === 'adult') {
          this.guests.adults++;
        } else if (guestType === 'children') {
          this.guests.children++;
        }

        this.getPeriodRate();
      }
    },
    reduceGuests(guestType) {
      if ((this.totalGuests <= this.guestLimit) && (this.guests.children >= 0)) {
        if (guestType === 'adult') {
          this.guests.adults--;
        } else if (guestType === 'children') {
          this.guests.children--;
        }

        this.getPeriodRate();
      }
    },
    goToReservationURI() {
      window.open(
          this.reservationUrl +
          '#checkin=' + DateTime.fromJSDate(this.reservationRange.start).toISODate() +
          '&checkout=' + DateTime.fromJSDate(this.reservationRange.end).toISODate() +
          '&room=1' +
          '&adults=' + this.guests.adults +
          '&kids=' + this.guests.children +
          '&rid=' + this.roomId,
          '_blank'
      );
    },
    async getPeriodRate() {
      this.loading = true;

      this.singleNightPrice = 0;
      this.singleNightPriceNumber = 0;
      this.totalNightsPrice = 0;
      this.totalExtraCharges = 0;
      this.totalFinalPrice = 0;
      this.offeredSingleNightPrice = 0;
      this.offeredSingleNightPriceNumber = 0;
      this.offeredTotalNightsPrice = 0;
      this.offeredTotalExtraCharges = 0;
      this.offeredTotalFinalPrice = 0;

      const { data } = await api.get('/cloudbeds/rate' +
          '?roomTypeID=' + this.roomTypeId +
          '&startDate=' + DateTime.fromJSDate(this.reservationRange.start).toISODate() +
          '&endDate=' + DateTime.fromJSDate(this.reservationRange.end).toISODate() +
          '&adults=' + this.guests.adults +
          '&children=' + this.guests.children +
          '&detailedRates=1'
      );

      this.mapRate(data);

      this.loading = false;
    },

    mapRate(data) {
      if (data.length === 0) {
        this.warning = 'Este apartamento no está disponible para las fechas seleccionadas';
      }

      if (data.ratePlanID) {
        this.offeredSingleNightPrice = data.averageRate;

        this.offeredTotalNightsPrice = data.roomRate;
        this.offeredTotalExtraCharges = data.extraRate;
        this.offeredTotalFinalPrice = data.totalRate;

        this.discount = data.derivedValue;
        this.discountType = data.derivedType === 'percentage' ? '%' : '€';

        this.singleNightPrice = ((parseFloat(data.baseRate) + parseFloat(data.extraRate)) / this.totalNights).toFixed(2);

        this.totalNightsPrice = data.baseRate;
        this.totalExtraCharges = data.extraRate;
        this.totalFinalPrice = parseFloat(data.baseRate) + parseFloat(data.extraRate);
      } else {
        this.singleNightPrice = data.averageRate;
        this.singleNightPriceNumber = parseFloat(this.singleNightPrice);

        this.totalNightsPrice = data.roomRate;
        this.totalExtraCharges = data.extraRate;
        this.totalFinalPrice = data.totalRate;
      }
    }
  },
  watch: {
    reservationRange() {
      this.getPeriodRate();
    },
  },
};
</script>

<style lang="scss">
.book-info {
  .info-date {
    top: 8px;
    left: 12px;
  }

  .counter .icon {
    font-size: .625rem
  }
}
</style>
